<template>
  <div>
    <PopupDialog :dialogOpen="dialog" />
    <Navbar />
    <v-row justify="center" class="ma-0">
      <v-col cols="12" md="6">
        <v-card
          class="pa-10"
          flat
          style="border-radius: 20px"
          v-if="state == 0"
        >
          <div class="primary--text display-1 font-weight-bold mb-3 ml-3">
            {{ $t("register.title") }}
          </div>
          <div class="grey--text title font-weight-regular mb-5 ml-3">
            {{ $t("register.accountType.title") }}:
          </div>
          <v-radio-group v-model="flag">
            <v-radio
              :label="$t('register.accountType.volunteer')"
              value="2"
            ></v-radio>
            <v-row class="mb-3"
              ><v-col cols="12" md="8" class="pl-10">
                <span>{{
                  $t("register.accountType.volunteerDescription")
                }}</span>
              </v-col></v-row
            >
            <v-radio
              :label="$t('register.accountType.organisation')"
              value="1"
            ></v-radio>
            <v-row class="mb-3"
              ><v-col cols="12" md="8" class="pl-10">
                <span>{{
                  $t("register.accountType.organisationDescription")
                }}</span>
              </v-col></v-row
            >
          </v-radio-group>
          <v-btn color="primary" class="mx-0 mt-3" @click="continue_form">{{
            $t("register.continue")
          }}</v-btn>
        </v-card>
        <v-card
          :class="[$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-10']"
          flat
          style="border-radius: 20px"
          v-else-if="state == 1"
        >
          <div
            :class="[
              'grey--text',
              'title',
              $vuetify.breakpoint.smAndDown ? 'my-5' : 'mb-5',
              'ml-5',
            ]"
          >
            {{ $t("register.registration") }}
          </div>
          <v-btn
            text
            color="primary"
            class="mx-0 mt-3 mb-5 plain"
            @click="state = 0"
          >
            <v-icon class="mr-2">arrow_back</v-icon>
            <span class="text-none">{{ $t("register.back") }}</span>
          </v-btn>
          <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
          <v-form class="px-3" @submit.prevent="register" ref="registerForm">
            <div v-if="flag == '2'">
              <div class="headline grey--text text--darken-2 mb-5">
                {{ $t("register.personalInformation.title") }}
              </div>
              <InputField
                :label="$t('register.personalInformation.name')"
                icon="perm_identity"
                v-model="formData.name"
                rules="required"
              />
              <InputField
                rules="emailTest"
                :label="$t('register.personalInformation.email')"
                icon="email"
                v-model="formData.email"
                inputType="email"
              />
              <InputField
                :label="$t('register.personalInformation.region')"
                icon="near_me"
                v-model="formData.region"
                rules="required"
                inputType="regionAutocomplete"
                :regionSlovakia="false"
              />
              <p
                class="
                  subtitle-1
                  grey--text
                  text--darken-2 text-justify
                  ma-3
                  mb-5
                "
              >
                {{ $t("register.personalInformation.descriptionText") }}
              </p>
              <InputField
                :label="$t('register.personalInformation.descriptionLabel')"
                icon="edit"
                v-model="formData.desc"
                rules="maxLength1000"
                inputType="textarea"
              />
            </div>
            <div v-else-if="flag == '1'">
              <div class="headline grey--text text--darken-2 mb-5">
                {{ $t("register.organisation.title") }}
              </div>
              <InputField
                :label="$t('register.organisation.name')"
                icon="business"
                v-model="formData.name"
                rules="maxLength64"
              />
              <InputGeosearch
                :label="$t('register.organisation.address')"
                icon="place"
                rules="maxLength100"
                @input="processAutoCompleteData"
              />
              <InputField
                :label="$t('register.organisation.region')"
                icon="near_me"
                v-model="formData.region"
                :inputValue="formData.region"
                rules="required"
                inputType="regionAutocomplete"
                :regionSlovakia="false"
              />
              <InputField
                :label="$t('register.organisation.description')"
                icon="edit"
                v-model="formData.desc"
                rules="maxLength1000"
                inputType="textarea"
              />
              <div class="headline grey--text text--darken-2 mb-5">
                {{ $t("register.organisation.contactTitle") }}
              </div>
              <InputField
                :label="$t('register.organisation.phone')"
                icon="local_phone"
                v-model="formData.telephone"
                rules="phoneTest"
              />
              <InputField
                rules="emailTest"
                :label="$t('register.organisation.email')"
                icon="email"
                v-model="formData.email"
                inputType="email"
              />
            </div>
            <div class="headline grey--text text--darken-2 mb-5">
              {{ $t("register.loginDetails.title") }}
            </div>
            <InputField
              :label="$t('register.loginDetails.username')"
              icon="account_circle"
              v-model="formData.username"
              rules="usernameTest"
            />
            <InputField
              :label="$t('register.loginDetails.password')"
              icon="lock"
              v-model="formData.password"
              rules="minLength"
              inputType="password"
            />
            <InputField
              :label="$t('register.loginDetails.repeatPassword')"
              icon="lock"
              v-model="formData.passwordRepeat"
              rules="samePass"
              inputType="password"
              :inputRulesData="formData.password"
            />
            <div v-if="flag == '2'">
              <div class="headline grey--text text--darken-2">
                {{ $t("register.personalInformation.skills") }}:
              </div>
              <div
                v-for="(tagName, index) in Object.keys($store.state.tags).filter(t => $store.state.tags[t].isUser)"
                :key="index"
              >
                <v-checkbox
                  v-model="formData.tags"
                  :label="$t(`tags.${tagName}.name`)"
                  :value="tagName"
                  hide-details
                >
                  <template v-slot:label>
                    <v-icon color="primary" class="mr-2">{{
                      $store.state.tags[tagName].icon
                    }}</v-icon>
                    {{ $t(`tags.${tagName}.name`) }}
                  </template>
                </v-checkbox>
                <v-row>
                  <v-col cols="12" md="8">
                    {{ $t(`tags.${tagName}.description`) }}
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-checkbox
              :rules="inputRules['required']"
              :label="$t('register.consent') + ' '"
              hide-details
              class="ma-0 mt-5"
            >
              <template v-slot:label="">
                <span class="caption"
                  >{{ $t("register.consent") }}
                  <span tag="span" class="primary--text ml-1" @click="terms">{{
                    $t("register.terms")
                  }}</span></span
                >
              </template>
            </v-checkbox>
            <div>
              <v-btn
                color="primary"
                class="mx-0 my-5"
                type="submit"
                :loading="loading"
                >{{ $t("register.register") }}</v-btn
              >
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import inputRulesMixin from "@/mixins/inputRulesMixin.js";
import Navbar from "@/components/partials/Navbar";
import InputField from "@/components/partials/InputField";
import PopupDialog from "@/components/partials/PopupDialog";
import InputGeosearch from "@/components/partials/InputGeosearch";

export default {
  name: "Register",
  components: { Navbar, InputField, InputGeosearch, PopupDialog },
  mixins: [inputRulesMixin],
  data() {
    return {
      formData: {
        username: "",
        password: "",
        passwordRepeat: "",
        email: "",
        //birth: '',
        name: "",
        desc: "",
        address: "",
        telephone: "",
        region: "",
        tags: [],
      },
      flag: "2",
      error: "",
      dialog: false,
      loading: false,
      state: 0,
      showPassword: false,
    };
  },
  methods: {
    processAutoCompleteData(data) {
      if (typeof data === "object") {
        this.formData.address = data.name;
        this.formData.region = data.region;
      } else {
        this.formData.address = data;
      }
    },
    register() {
      if (this.$refs.registerForm.validate()) {
        this.loading = true;
        if (
          this.formData["telephone"] == "" ||
          this.formData["telephone"] == null
        ) {
          this.formData["telephone"] = null;
        } else {
          this.formData["telephone"] = this.formData["telephone"].replace(
            /\s/g,
            ""
          );
        }
        this.$store
          .dispatch("register", {
            ...this.formData,
            flag: this.flag,
          })
          .then(() => {
            this.error = "";
            this.loading = false;
            this.$router.push({ name: "login", query: { register: true } });
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              const msg = error.response.data.message;
              this.loading = false;
              if (msg === "Username taken") {
                this.error = this.$t("register.errors.usernameTaken");
                window.scrollTo(0, 0);
              } else if (msg.toLowerCase().includes("email")) {
                this.error = this.$t("register.error.emailTaken");
                window.scrollTo(0, 0);
              } else if (msg.includes("INVALID DATA:")) {
                this.error = this.$t("error.form");
                window.scrollTo(0, 0);
              } else {
                this.dialog = true;
              }
            } else {
              this.dialog = true;
            }
          });
      } else {
        this.error = this.$t("register.errors.fixErrors");
        window.scrollTo(0, 0);
      }
    },
    continue_form() {
      this.state = 1;
      this.formData = {
        username: "",
        password: "",
        passwordRepeat: "",
        email: "",
        //birth: '',
        name: "",
        desc: "",
        address: "",
        telephone: "",
        region: "",
        tags: [],
      };
      this.showPassword = false;
      this.error = "";
    },
    terms() {
      let routeData = this.$router.resolve({ name: "terms" });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>